<ng-container *ngIf="placeholder == 'default'">
  <div class="placeholder">
    <div class="placeholder-thumb is-2-1"></div>
  </div>
</ng-container>

<ng-container *ngIf="placeholder == 'lines'">
  <div class="placeholder">
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
    <div class="placeholder-line  mt-xxs"></div>
  </div>
</ng-container>

<ng-container *ngIf="placeholder == 'product'">
  <div class="placeholder is-product">
    <div class="placeholder-thumb"></div>
    <div class="placeholder-line mt-xs"></div>
    <div class="placeholder-line  mt-xxs"></div>
  </div>
</ng-container>

<ng-container *ngIf="placeholder == 'category'">
  <div class="placeholder is-category">
    <div class="placeholder-thumb"></div>
    <div class="placeholder-line mt-xs"></div>
  </div>
</ng-container>

<ng-container *ngIf="placeholder == 'blog'">
  <div class="placeholder is-category">
    <div class="placeholder-thumb is-3-2"></div>
    <div class="placeholder-line mt-xs max-xs is-sm"></div>
    <div class="placeholder-line mt-xxs"></div>
  </div>
</ng-container>

<ng-container *ngIf="placeholder == 'product-single'">
  <div class="placeholder is-productSingle">
    <div class="row  gx-5">
      <div class="col-lg-6">
        <div class="placeholder-thumb"></div>
        <div class="d-flex mt-xs">
          <div class="placeholder-circle me-xxs"></div>
          <div class="placeholder-circle me-xxs"></div>
          <div class="placeholder-circle me-xxs"></div>
          <div class="placeholder-circle me-xxs"></div>
        </div>
        <div class="mb-lg d-lg-none"></div>
      </div>
      <div class="col-xl-5 col-lg-6">
        <div class="placeholder-line is-lg mb-xs"></div>
        <div class="placeholder-line is-lg mb-xs"></div>

        <div class="placeholder-line mb-xxs max-80"></div>
        <div class="placeholder-line mb-xs"></div>

        <div class="placeholder-line mb-xxs max-80"></div>
        <div class="placeholder-line mb-xs"></div>

        <div class="placeholder-line mb-xxs max-80"></div>
        <div class="placeholder-line mb-md"></div>
        <div class="placeholder-line is-xl  mb-xs"></div>
        <div class="placeholder-line is-xl  "></div>
      </div>
    </div>
    <div class="mt-xl">
      <div class="row">
        <div class="col-lg-7">
          <div class="placeholder-line is-lg mb-md"></div>
          <div class="row">
            <div class="col-6">
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
            </div>
            <div class="col-6">
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
              <div class="placeholder-line mb-xs"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="placeholder-line is-lg mb-md"></div>
          <div class="placeholder-line mb-xs"></div>
          <div class="placeholder-line mb-xs"></div>
          <div class="placeholder-line mb-xs"></div>
          <div class="placeholder-line mb-xs"></div>
        </div>

      </div>
    </div>

  </div>
</ng-container>

