import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FacadeService } from '@core/facade/facade.service';

@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
  @Input() show: any = false;
  @Input() addressItem: any;

  @Output() modalChange = new EventEmitter<boolean>();
  @Output() addressUpdatedAdded = new EventEmitter<any>();
  updateAddressForm!: FormGroup;
  isAddAddress!: boolean;
  isSubmitting: boolean =false;
  lang:string = '';
  close(show: any, address?: any) {
    this.updateAddressForm.reset();
    this.addressItem = undefined;
    this.modalChange.emit(show);
    if (address) {

      this.addressUpdatedAdded.emit(address);
    }
  }

  addressTypes: any;


  status : any = "47";
  cities: any;
  sellectedAreas: any;

  constructor(
    private facade: FacadeService
  ) {
  }

  ngOnInit(): void {
    this.addressTypesFunc();
    this.Allcities();

    this.updateAddressForm = this.facade.fb({
      address: ['', Validators.required],
      cityId: [ '', Validators.required],
      areaId: ['', Validators.required],
      type: ['', Validators.required],
      deliveryNote: ['']
    });
    this.lang = localStorage.getItem('lang')?.indexOf('en')? '' :'ar'
  }

  Allcities() {
    this.facade
    .getCities()
    .subscribe((res: any) => {
      if (res) {
        this.cities = res.data.cities;
        if (this.addressItem) {
          this.patchAdress(this.addressItem);
        }
      }
    });
  }

  addressTypesFunc() {
    this.facade.getAdressType().subscribe((res: any) => {
      this.addressTypes = res?.data?.addressTypes;
    })
  }

  changeCity(cityId?: any) {
    if (cityId?.target?.value && this.cities?.length > 0) {
    const city = this.cities.filter((el: any) => el.cityId == cityId?.target?.value);
    this.sellectedAreas = city[0].areas;
    }

  }

  updateAdress() {
    if (this.addressItem?.addressId) {
      this.updateAddressForm.value.addressId = this.addressItem?.addressId;
      this.updateAddress();
    } else {
      delete this.updateAddressForm.value.addressId;
      this.addAddress();
    }
  }


  updateAddress() {
    const variables = {
      updateAddressInput: this.updateAddressForm.value,
    }
    this.isAddAddress = true;
    this.isSubmitting = true;
    this.facade
    .updateAdress(variables)
    .subscribe((res: any) => {
      this.updateAddressForm.reset();
      this.close(false, res);
      this.isAddAddress = false;
      if (res) {
        this.facade.success('Address updated successfully',`Address`);
        this.isSubmitting = false;
      }
    }, (error: any) => {
      this.isAddAddress = false;
      this.isSubmitting = false;
      this.facade.handleError(error);
    });
  }

  addAddress() {
    const variables = {
      createAddressInput: this.updateAddressForm.value,
    }
    this.isAddAddress = true;
    this.facade
    .addAdress(variables)
    .subscribe((res: any) => {
      this.updateAddressForm.reset();
      this.isAddAddress = false;
      if (res) {
        this.close(false ,res);
        this.facade.success('Address added successfully',`Address`);
      }
    }, (error: any) => {
      this.isAddAddress = false;
      this.facade.handleError(error);
    });
  }

  patchAdress(data: any) {
    if (this.addressItem?.addressId) {
      this.updateAddressForm.value.addressId = this.addressItem?.addressId;

      this.changeCity(data?.city?.cityId);

      this.updateAddressForm.setValue({
        type: data?.type?.name,
        address: data?.address,
        cityId: data?.city?.cityId,
        areaId: data?.areaId,
        deliveryNote: data?.deliveryNote
    });
    }
  }

  get address() {
    return this.updateAddressForm.get('address');
  }

  get cityId() {
    return this.updateAddressForm.get('cityId');
  }

  get areaId() {
    return this.updateAddressForm.get('areaId');
  }

  get type() {
    return this.updateAddressForm.get('type');
  }

  get deliveryNote() {
    return this.updateAddressForm.get('deliveryNote');
  }

}
