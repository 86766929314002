import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() src: string = `assets/images/default.jpg`;
  @Input() zoom!: any;
  @Input() alt = 'Image';
  @Input() ratio = '';

  constructor() {}

  ngOnInit(): void {}
}
