<a
  (click)="goToCategoryProduct()"
  class="categoryCard cursor-pointer"
  [title]="name"
>
  <div class="categoryCard-header">
    <app-image [src]="image" [alt]="name" ratio="is-1-1"></app-image>
  </div>
  <h4 class="categoryCard-title">{{ name }}</h4>
  <p>( {{ productsCount }} )</p>
</a>
