<div class="theHeader" (clickOutside)="searchOpened = false">
  <div class="theHeader-container container">
    <div class="theHeader-start">
      <!-- <a class="theHeader-menu-icon me-xs d-none d-md-block" href="javascript:void(0)" title="menu"
        (click)="showSidebar = !showSidebar">
        <svg-icon src="assets/icons/menu.svg"></svg-icon>
      </a> -->
      <a class="theHeader-logo" [routerLink]="['/']" title="Romeo">
        <svg-icon src="assets/icons/logo.svg"></svg-icon>
      </a>
    </div>

    <ul class="theHeader-nav">
      <li class="d-none d-lg-flex">
        <a
          [routerLink]="['/categories']"
          title="{{ 'shared.categories' | translate }}"
          >{{ "shared.categories" | translate }}</a
        >
      </li>
      <li class="d-lg-flex">
        <a
          [routerLink]="['/bundles']"
          title="{{ 'shared.bundles' | translate }}"
          >{{ "shared.bundles" | translate }}</a
        >
      </li>
      <li class="d-none d-lg-flex">
        <a
          [routerLink]="['/about']"
          title="{{ 'header.aboutUs' | translate }}"
          >{{ "shared.aboutUs" | translate }}</a
        >
      </li>
      <li class="d-none d-lg-flex">
        <a [routerLink]="['/blog']" title="{{ 'shared.blog' | translate }}">{{
          "shared.blog" | translate
        }}</a>
      </li>
      <!-- <li class="d-none d-lg-flex">
        <a [routerLink]="['/']" title="Rewards">Rewards</a>
      </li>
      <li class="d-none d-lg-flex">
        <a [routerLink]="['/']" title="Gift Cards">Gift Cards</a>
      </li>
      <li class="d-none d-sm-flex">
        <a [routerLink]="['/']" title="العربية">
          <svg-icon src="assets/icons/lang.svg"></svg-icon>
          <span>
            العربية
          </span>
        </a>
      </li> -->
      <li class="d-md-flex">
        <a [routerLink]="['/set']" title="Create set" class="btn btn-dark">
          {{ "header.createSet" | translate }}
        </a>
      </li>
      <li class="me-2 d-flex align-items-center me-md-0">
        <a
          href="javascript:void(0)"
          (click)="showSearch()"
          title="Search"
          class="theHeader-nav-icon"
        >
          <svg-icon src="assets/icons/search.svg"></svg-icon>
        </a>
      </li>
      <li class="d-none d-md-flex">
        <a
          [routerLink]="['/account/whishlist']"
          title="Favorites"
          class="theHeader-nav-icon"
        >
          <svg-icon src="assets/icons/heart.svg"></svg-icon>
          <span class="theHeader-nav-icon-badge">{{ wishList.length }}</span>
        </a>
      </li>
      <li class="d-none d-md-flex">
        <a [routerLink]="['/checkout']" title="Cart" class="theHeader-nav-icon">
          <svg-icon src="assets/icons/cart.svg"></svg-icon>
          <span class="theHeader-nav-icon-badge">{{ cartDetailLength }}</span>
        </a>
      </li>
      <li class="d-none d-md-flex">
        <a
          [routerLink]="['/account']"
          title="Account"
          class="theHeader-nav-icon"
        >
          <svg-icon src="assets/icons/person.svg"></svg-icon>
        </a>
      </li>
      <!-- <li class="d-md-flex">
        <a [routerLink]="['/set']" title="Create set" class="btn btn-dark">
          {{ "header.createSet" | translate }}
        </a>
      </li> -->
      <li *ngIf="currentCurrency?.length > 0" class="d-none d-md-flex">
        <a href="javascript:void(0)">
          <span class="me-xxs">{{ currentCurrency[0].isoAlpha_3 }}</span>
          <svg-icon src="assets/icons/chevron-down.svg"></svg-icon>
        </a>
        <ul>
          <li>
            <a
              href="javascript:void(0)"
              *ngFor="let currency of currencies"
              (click)="changeCurrency(currency)"
              title="USD"
              >{{ currency?.name || currency?.nameAr }}</a
            >
          </li>
        </ul>
      </li>
      <li class="d-none d-md-flex">
        <a
          href="javascript:void(0)"
          (click)="switchLang()"
          title="{{ language }}"
        >
          <svg-icon src="assets/icons/lang.svg"></svg-icon>
          <span class="me-xxs">
            {{ language }}
          </span>
        </a>
      </li>
    </ul>
    <div
      class="theHeader-search container"
      [ngClass]="{ 'is-active': searchOpened }"
    >
      <div class="theHeader-search-inner">
        <input
          class="theHeader-search-input"
          type="search"
          #search
          placeholder="What are you looking for?"
          (focus)="searchFocus()"
          (keyup.enter)="searchWithEnter()"
          [(ngModel)]="searchKey"
          (input)="searchByKey()"
        />
        <ul
          class="theHeader-search-results"
          *ngIf="products?.length && searchKey"
        >
          <li
            appDropDownNavigate
            (keyUpDown)="navigateDropDownSearch($event)"
            *ngFor="let product of products; let i = index"
            (click)="searchWithEnter(product)"
            [index]="i"
            [lenth]="products?.length"
          >
            <a
              [routerLink]="['/product/single']"
              [innerHTML]="product.highlightedName || product.highlightedNameAr"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="theHeader-search-bg" *ngIf="searchOpened"></div>
<app-sidebar
  *ngIf="showSidebar"
  (hideSideBar)="showSidebar = !showSidebar"
></app-sidebar>
<romeo-tabs></romeo-tabs>
