import { LocalStorageService } from './core/services/local-storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject } from '@angular/core';
import { SidebarService } from '@core/services/sidebar/sidebar.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Romeo';
  constructor(public SidebarService: SidebarService, public translateService: TranslateService,
              public storage: LocalStorageService,@Inject(DOCUMENT) private document: Document) {
    this.initializeApp();
  }

  initializeApp(){
    if(localStorage.getItem('lang') && localStorage.getItem('lang')?.indexOf("ar") != -1) {
      localStorage.setItem('lang',"ar");
      this.translateService.setDefaultLang("ar");
      this.document.dir = 'rtl'
    }else {
      
      localStorage.setItem('lang',"en");
      this.translateService.setDefaultLang("en");
      this.document.dir = 'ltr';
    }
  }
}
