import { FacadeService } from './../../facade/facade.service';
import { LocalStorageService } from './../../services/local-storage/local-storage.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Currencies } from '@core/enums/enums';
import { SharingDataService } from '@core/services/sharing-data/sharing-data.service';
// import { UserService } from '@resources/services/auth/user.service';
// import { CartService } from '@resources/services/cart/cart.service';
// import { SharingDataService } from '@resources/services/sharing-data/sharing-data.service';

@Component({
  selector: 'app-tab-settings',
  templateUrl: './tab-settings.component.html',
  styleUrls: ['./tab-settings.component.scss']
})
export class TabSettingsComponent implements OnInit {
  @Input() showSettinsTab!:boolean;
  currentLang!: string;
  token!: any;
  curencies: any[] = [];
  currentCurrency: any;
  defultCurr = Currencies.EGP;


  constructor(@Inject(DOCUMENT) private _document: Document,
  private router: Router,
  private storageService: LocalStorageService, private facade: FacadeService,
  public sharingService: SharingDataService
  ) { }

  ngOnInit(): void {
    this.getLang();
    this.token = localStorage.getItem('AUTH_TOKEN');
    this.getCurencies();
  }


  getCurencies() {
    this.facade.getCurencies().subscribe((res: any) => {
      this.curencies = res?.data?.currencies;
      if (sessionStorage.getItem('currency')) {
        this.currentCurrency = JSON.parse(sessionStorage.getItem('currency')!);
      } else {
        this.currentCurrency = this.curencies.filter(
          (el: any) => el?.isoAlpha_3 == this.defultCurr
        );
        sessionStorage.setItem(
          'currency',
          JSON.stringify(this.currentCurrency)
        );
      }
    });
  }

  changeLang(lang:any) {
    // this.langService.switchLang(lang)
    this.storageService.remove('lang');
    this.storageService.set('lang', lang);

    this.getLang();
    this.refreshPage();
  }

  changeCurrency(curency:any) {
    if (
      curency?.isoAlpha_3 !=
      JSON.parse(String(sessionStorage.getItem('currency')))[0].isoAlpha_3
    ) {
      this.currentCurrency = [curency];
      sessionStorage.setItem('currency', JSON.stringify(this.currentCurrency));
      this._document.defaultView?.location.reload();
    }
  }

  refreshPage() {
    this._document.defaultView?.location.reload();
  }


  getLang() {
    if (localStorage.getItem('lang')) {
      this.currentLang = String(localStorage.getItem('lang'));
    } else {
      this.currentLang = 'en';
      localStorage.setItem('lang', 'en');
    }
  }

  logOut() {
    // this.userService.logOut();
    localStorage.clear();
    this.sendCartItemsToCartNotifications([]);
    // this.wishList = [];
    this.token = undefined;
    this.sendFaveItemToFaveNotification();
    this.sharingService.nextAuthenticated(false);

  }

  sendFaveItemToFaveNotification(){
    // this.sharingDataService.nextFave('delete')
  }

  sendCartItemsToCartNotifications(res:any) {
    // this.sharingDataService.nextCount(res);
  }

  logIn() {
    localStorage.setItem('redirectTo', this.router.url);
    this.router.navigate(['../auth']);
  }

  register() {
    this.router.navigate(['/auth/register']);
  }

}
