<div class="sidebarOverlay is-active">
  <div class="sidebarOverlay-bg" (click)="hideSide()"></div>
  <div class="sidebarOverlay-container">
    <div class="text-center  mb-lg">
      <button class="sidebarOverlay-close btn" (click)="hideSide()">
        <svg-icon src="assets/icons/close.svg"></svg-icon>
      </button>
    </div>
    <div class="pe-lg ps-lg">
      <ul class="sidebarOverlay-nav">
        <li>
          <a [routerLink]="['/categories']" title="Categories">{{ 'shared.categories'  | translate }}</a>
        </li>
        <!-- <li>
          <a [routerLink]="['/']" title="Bundels">Bundels</a>
        </li>
        <li>
          <a [routerLink]="['/']" title="Rewards">Rewards</a>
        </li>
        <li>
          <a [routerLink]="['/']" title="Gift Cards">Gift Cards</a>
        </li>-->
        <li>
          
        </li> 
      </ul>

      <a class="btn btn-dark w-100  mt-md" [routerLink]="['/set']" title="Create Set">{{'set.createYourSet' | translate }}</a>

    </div>



  </div>

</div>
