<div class="menu-overlay" *ngIf="showSettinsTab"></div>
<div class="filter-menu pb-100" [class.menueSlide]="showSettinsTab">

  <!-- <ng-container *ngIf="token">
    <div class="text-dark size-4 font-medium cursor-pointer mb-4 line" [routerLink]="['/account']"> {{'header.accountInformation' | translate}}
      </div>
  </ng-container> -->

  <ng-container>
    <div class="text-dark size-4 font-medium cursor-pointer lang mb-4 line" *ngIf="currentLang.indexOf('en') != -1"
      (click)="changeLang('ar')"> العربية </div>
    <div class="text-dark size-4 font-medium cursor-pointer lang mb-4 line"  *ngIf="currentLang.indexOf('ar') != -1"
      (click)="changeLang('en')"> English</div>
  </ng-container>

  <ng-container>
    <div class="text-dark size-4 font-medium cursor-pointer mb-4 line" *ngFor="let curency of curencies"
      (click)="changeCurrency(curency)">{{curency?.name || curency?.nameAr}}</div>
  </ng-container>

  <!-- <ng-container *ngIf="token">
    <div class="text-dark size-4 font-medium cursor-pointer mb-4 line" [routerLink]="['/account']">
      {{'header.accountInformation' | translate}}
    </div>
  </ng-container> -->

  <ng-container *ngIf="!token">
    <div class="text-dark size-4 font-medium cursor-pointer mb-4 line" (click)="logIn()">{{'auth.signIn' | translate}}
    </div>
    <div class="text-dark size-4 font-medium cursor-pointer  mb-4 line" (click)="register()">{{'auth.signUp' |
      translate}}</div>
  </ng-container>


  <div class="cursor-pointer size-8 font-medium mtb-6" *ngIf="token">
    <div class="text-dark size-4 font-medium cursor-pointer  mb-4 line" (click)="logOut()">{{'shared.logout' |
      translate}}</div>
  </div>

</div>
