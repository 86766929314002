    <div class="menu-overlay" *ngIf="showAccMenu" ></div>
    <div class="filter-menu pb-100" [class.menueSlide]="showAccMenu">
        <ul class="accountNav">
            <li>
              <a [routerLink]="['/account/orders']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-active"
                title="Orders">
                <svg-icon class="me-xxs" src="assets/icons/list.svg"></svg-icon>
                <span>{{ 'account.orders' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/details']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Personal information">
                <svg-icon class="me-xxs" src="assets/icons/contact.svg"></svg-icon>
                <span>{{ 'account.personalInformation' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/addresses']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Saved address">
                <svg-icon class="me-xxs" src="assets/icons/map.svg"></svg-icon>
                <span>{{ 'account.savedAddress' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/change-password']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Change password">
                <svg-icon class="me-xxs" src="assets/icons/key.svg"></svg-icon>
                <span>{{ 'account.changePassword' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/subscriptions']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Subscriptions">
                <svg-icon class="me-xxs" src="assets/icons/near.svg"></svg-icon>
                <span>{{ 'account.subscriptions' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/reviews']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Reviews">
                <svg-icon class="me-xxs" src="assets/icons/star-circle.svg"></svg-icon>
                <span>{{ 'shared.reviews' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/account/whishlist']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active" title="Wishlist">
                <svg-icon class="me-xxs" src="assets/icons/heart.svg"></svg-icon>
                <span>{{ 'account.wishlist' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-active" (click)="logOut()"
                title="Logout">
                <svg-icon class="me-xxs" src="assets/icons/exit.svg"></svg-icon>
                <span>{{ 'shared.logout' | translate }}</span>
              </a>
            </li>
          </ul>
    </div>


    <!-- <div class="cursor-pointer size-6 font-bold mb-6"><a [routerLink]="['/account']"
        routerLinkActive="router-link-active" class="text-decoration-none w-100 text-dark"> {{'auth.Accountinformation' | translate}}</a></div>
<div class="text-gray3 cursor-pointer font-medium size-3 mb-2"><a [routerLink]="['/account']"
        routerLinkActive="router-link-active" class="text-decoration-none w-100 text-gray3"> {{'auth.MyDetails' | translate}} </a></div>
<div class="text-gray3 cursor-pointer font-medium size-3 mb-16"><a [routerLink]="['/account/shipping']"
        routerLinkActive="router-link-active" class="text-decoration-none w-100 text-gray3"> {{'auth.ShippingAddress' | translate}}</a></div>
<div class="text-dark cursor-pointer size-6 font-bold mb-8">
    <a [routerLink]="['/account/track-order']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark"> {{'auth.TrackOrder' | translate}}</a>
</div>
<div
    class="text-dark cursor-pointer size-6 font-bold mb-8 max-260 d-flex align-items-center justify-content-between">
    <a [routerLink]="['/account/orders']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark"> {{'auth.MyOrders' | translate}}</a> -->
    <!-- <div class="bg-pink1 text-white d-flex align-items-center max-30 border-radius-8 w-100 justify-content-center size-2 font-bold p-2">2</div> -->
<!-- </div>
<div
    class="text-dark cursor-pointer size-6 font-bold mb-8 max-260 d-flex align-items-center justify-content-between">
    <a [routerLink]="['/account/whishlist']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark" (click)="faveNotification = 0"> {{'auth.WishList' | translate}}</a>
    <div class="bg-pink1 text-white d-flex align-items-center max-30 border-radius-8 w-100 justify-content-center size-2 font-bold p-2 "
        *ngIf="faveNotification">{{faveNotification}}</div>
</div>
<div class="text-dark cursor-pointer size-6 font-bold mb-8">
    <a [routerLink]="['/account/mumez-subscribe']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark"> {{'auth.MumezSubscription' | translate}}</a>
</div>
<div class="text-dark cursor-pointer size-6 font-bold mb-8">
    <a [routerLink]="['/account/reviews']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark"> {{'auth.ProductReviews' | translate}}</a>
</div>
<div class="text-dark cursor-pointer size-6 font-bold mb-8">
    <a [routerLink]="['/account/return-form']" routerLinkActive="router-link-active"
        class="text-decoration-none w-100 text-dark"> {{'auth.ReturnForm' | translate}}</a>
</div> -->
