import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageComponent } from '@shared/components/image/image.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { StarRateComponent } from './components/star-rate/star-rate.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { RouterModule } from '@angular/router';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { DropDownNavigateDirective } from './directives/drop-down-navigate/drop-down-navigate.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { BundleCardComponent } from './components/bundle-card/bundle-card.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    ImageComponent,
    StarRateComponent,
    ProductCardComponent,
    CategoryCardComponent,
    PlaceholderComponent,
    BreadCrumbComponent,
    AddressModalComponent,
    DropDownNavigateDirective,
    BundleCardComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    LazyLoadImageModule,
    RouterModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    TranslateModule.forChild({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    },
    isolate: false
  }),
  NgxImageZoomModule
  ],
  exports: [
    HttpClientModule,
    AngularSvgIconModule,
    ImageComponent,
    StarRateComponent,
    ProductCardComponent,
    CategoryCardComponent,
    PlaceholderComponent,
    BreadCrumbComponent,
    AddressModalComponent,
    BundleCardComponent,
    ClickOutsideModule,
    DropDownNavigateDirective,
    TranslateModule
  ],
})
export class SharedModule {}
