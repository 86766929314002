<nav class="fixed-bottom d-block d-lg-none">
    <div class="d-flex align-items-center justify-content-around bg-tabs tabs">
        <a routerLink="/" routerLinkActive="active-tab" class="ptb-2 pse-4 ptb-sm-4 pse-sm-6 text-decoration-none" [routerLinkActiveOptions]="{exact:true}">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="../../../../assets/images/tab-home.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-1 size-3">{{'shared.home' | translate}}</div>
            </div>
        </a>
        <a  routerLinkActive="active-tab" class="ptb-2 pse-4 ptb-sm-4 pse-sm-6 text-decoration-none" *ngIf="token">
            <div class="d-flex flex-column justify-content-center align-items-center" (click)="showAccMenu = !showAccMenu;">
                <img src="../../../../assets/images/tab-profile.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-1 size-3">{{'tabs.profile' | translate}}</div>
                <app-tab-profile [showAccMenu]="showAccMenu"></app-tab-profile>
            </div>
        </a>
        <a routerLink="/categories" routerLinkActive="active-tab" class="ptb-2 pse-4 ptb-sm-4 pse-sm-6 text-decoration-none">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="../../../../assets/images/tab-category.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-1 size-3">{{'shared.categories' | translate}}</div>
            </div>
        </a>
        <!-- <a routerLink="/search" routerLinkActive="active-tab"  [queryParams]="{id: 'sale'}" class="ptb-2 pse-4 ptb-sm-4 pse-sm-6 text-decoration-none">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="../../../../assets/images/sale.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-5 size-3">{{'tabs.Sales' | translate}}</div>
            </div>
        </a> -->
        <a routerLink="/checkout" routerLinkActive="active-tab"  class="ptb-2 pse-4 ptb-sm-4 pse-sm-6 text-decoration-none">
            <div class="d-flex flex-column justify-content-center align-items-center card-holder">
                <img src="../../../../assets/images/tab-cart.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-2 size-3">{{'tabs.cart' | translate }}</div>
            </div>
        </a>
        <a  routerLinkActive="active-tab" class="ptb-2 pse-4 ptb-sm-4 pse-sm-6">
            <div class="d-flex flex-column justify-content-center align-items-center" (click)="showSettinsTab = !showSettinsTab">
                <img src="../../../../assets/images/tab-setting.svg" alt="" class="w-100 max-30">
                <div class="text-dark size-sm-4 mt-1 size-3">{{'tabs.settings' | translate}}</div>
                <app-tab-settings [showSettinsTab]="showSettinsTab"></app-tab-settings>
            </div>
        </a>
    </div>
</nav>
