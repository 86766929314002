<div class="theFooter">
  <div class="container">
    <div class="row mb-4 mb-md-0">
      <div (click)="navigateTohome()" class="col-lg-4 theFooter-col cursor">
        <svg-icon src="assets/icons/logo.svg" class="theFooter-logo"></svg-icon>
        <p
          class="mb-0 mt-sm"
          [innerHTML]="sittingsValues?.summary || sittingsValues?.summaryAr"
        ></p>
      </div>
      <div class="col-lg-3 theFooter-col">
        <h4 class="mb-sm">{{ "footer.shop" | translate }}</h4>
        <ul class="theFooter-nav">
          <li
            *ngFor="let category of categoriesArr | slice: 0:10"
            class="cursor"
          >
            <a
              [routerLink]="['/']"
              (click)="goToCategoryProduct(category?.categoryId)"
              title="{{ category.name || category.nameAr }}"
              >{{ category.name || category.nameAr }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-3 theFooter-col">
        <h4 class="mb-sm">{{ "footer.support" | translate }}</h4>
        <ul class="theFooter-nav">
          <li>
            <a [routerLink]="['/about']" title="About us">{{
              "shared.aboutUs" | translate
            }}</a>
          </li>
          <!-- <li><a [routerLink]="['/']" title="{{ 'footer.accessibility' | translate }}">{{ 'footer.accessibility' | translate }}</a></li> -->
          <li>
            <a
              [routerLink]="['/privacy-policy']"
              title="{{ 'shared.privacyPolicy' | translate }}"
              >{{ "shared.privacyPolicy" | translate }}</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/contact']"
              title="{{ 'shared.helpAndContactUs' | translate }}"
              >{{ "shared.helpAndContactUs" | translate }}</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/terms']"
              title="{{ 'shared.termsAndConditions' | translate }}"
              >{{ "shared.termsAndConditions" | translate }}</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/blog']"
              title="{{ 'shared.blog' | translate }}"
              >{{ "shared.blog" | translate }}</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/return-policy']"
              title="{{ 'shared.returnPolicy' | translate }}"
              >{{ "shared.returnPolicy" | translate }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-2 theFooter-col">
        <h4 class="mb-sm">{{ "footer.followUs" | translate }}</h4>
        <div>
          <div class="d-flex align-items-center mb-2">
            <svg-icon src="../../../../assets/icons/phone.svg"></svg-icon>
            <a class="ms-2" href="tel:9300767251">{{
              sittingsValues?.phone
            }}</a>
          </div>
          <div class="d-flex align-items-center mb-2">
            <svg-icon
              class="overflow-show"
              src="../../../../assets/icons/maail.svg"
            ></svg-icon>
            <a class="ms-2" href="mailto:Elody.Mosciski72@yahoo.com">{{
              sittingsValues?.email
            }}</a>
          </div>
          <div class="d-flex align-items-center mb-2">
            <svg-icon
              src="../../../../assets/icons/location.svg"
              class="address"
            ></svg-icon>
            <div
              class="ms-2"
              [innerHtml]="sittingsValues?.address || sittingsValues?.addressAr"
            ></div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <a
            class="d-inline-block me-xs fs-lg"
            href="{{ sittingsValues?.facebook }}"
            target="_blank"
            title="facebook"
          >
            <svg-icon src="assets/icons/facebook.svg"></svg-icon>
          </a>
          <a
            class="d-inline-block me-xs fs-lg"
            href="{{ sittingsValues?.twitter }}"
            target="_blank"
            title="twitter"
          >
            <svg-icon src="assets/icons/twitter.svg"></svg-icon>
          </a>
          <a
            class="d-inline-block me-xs fs-lg"
            href="{{ sittingsValues?.instagram }}"
            target="_blank"
            title="instagram"
          >
            <svg-icon src="assets/icons/instagram.svg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
