import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Currencies } from '@core/enums/enums';
import { FacadeService } from '@core/facade/facade.service';
import { Utils } from '@core/utils/utils';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	currencies: any;
	currentCurrency!: any;
	defultCurr: string = Currencies?.EGP;
	showSidebar: boolean = false;
	util = new Utils();
	@ViewChild('search') searchElement: ElementRef | undefined;

	searchOpened: Boolean = false;
	cartDetailLength: any = 0;
	token!: string;
	wishList: any[] = [];
	products: any[] = [];

	newCashCartDetails!: any[];
	cartIdByToken: any;
	cartProductsByToken: any[] = [];
	cartId!: any;
	currentLang!: any;
	allCategories: any[] = [];
	roomCategorieslist!: any[];
	slogans: any;

	constructor(public facade: FacadeService) {
	}

	searchKey!: string;
	showDropDown: boolean = false;
	currency: any;
	lang: string = 'en';
	language!: string;
	ngOnInit(): void {
		this.getCurencies();
		this.getLang();
		this.getFeaturedCategories();

		this.checkIcarthasIdOrToken();
		this.getFavorites();

		this.sharingDataServicCartItems();
		this.sharingDataServicFavItems();
		this.trigerNotfication();
		this.lang = localStorage.getItem('lang') || 'en';
		this.initLanguage();
	}

	getCurencies() {
		this.facade.getCurencies().subscribe((res: any) => {
			this.currencies = res?.data?.currencies;
			if (sessionStorage.getItem('currency')) {
				this.currentCurrency = JSON.parse(sessionStorage.getItem('currency')!);
			} else {
				this.currentCurrency = this.currencies.filter(
					(el: any) => el?.isoAlpha_3 == this.defultCurr
				);
				sessionStorage.setItem(
					'currency',
					JSON.stringify(this.currentCurrency)
				);
			}
		});
	}

	trigerNotfication() {
		this.facade.trigerNotficationsInHeader().subscribe((res: any) => {
			this.checkIcarthasIdOrToken();
			this.getFavorites();
		});
	}

	navEnter(e: any) {
		e.target.classList.add('is-active');
	}
	navLeave(e: any) {
		e.target.classList.remove('is-active');
	}

	showSearch() {
		this.searchOpened = true;
		setTimeout(() => {
			if (this.searchElement) {
				this.searchElement.nativeElement.focus();
			}
		}, 0);
	}

	validateCart() {
		if (localStorage.getItem('cartId')) {
			this.checkIfCartIdValid(localStorage.getItem('cartId'));
		} else if (localStorage.getItem('cartId_Guest')) {
			this.checkIfCartIdValid(localStorage.getItem('cartId_Guest'));
		} else {
			this.checkIcarthasIdOrToken();
		}
	}

	changeCurrency(curency: any) {
		this.currency = JSON.parse(sessionStorage.getItem('currency')!);
		this.currency = this.currency[0];
		if (curency?.isoAlpha_3 != this.currency?.isoAlpha_3) {
			this.currentCurrency = [curency];
			sessionStorage.setItem('currency', JSON.stringify(this.currentCurrency));
			this.refreshPage();
		}
	}

	getFeaturedCategories() {
		const variables = {
			paginationOptions: {
				page: 1,
				limit: 6,
			},
		};

		this.facade.getCategories(variables).subscribe((data: any) => {
			if (data) {
				this.allCategories = [...data?.data?.categories];
			}
		});
	}

	goToCategoryProduct(id: any, name: any, e: any) {
		this.facade.navigate(['/category'], {
			queryParams: { categoryId: this.util.createSlug(name, id) },
		});
		e.target.closest('.is-active').classList.remove('is-active');
	}

	goToSubCategoriesProducts(id: any, name: any, e: any) {
		this.facade.navigate(['/category'], {
			queryParams: { subCategoryId: this.util.createSlug(name, id) },
		});
		e.target.closest('.is-active').classList.remove('is-active');
	}

	checkIfCartIdValid(cartId: any) {
		const variables = {
			cartId: cartId,
		};
		this.facade.getCart(variables).subscribe(
			(res: any) => {
				if (res) {
					this.checkIcarthasIdOrToken();
				}
			},
			(error: any) => {
				this.ifNoSuchCart(error);
			}
		);
	}

	ifNoSuchCart(error: any) {
		if (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 404) {
			this.facade.removeItem('cartId');
			this.facade.removeItem('cartId_Guest');
			this.facade.createCart().then((res: any) => {
				if (res) {
					this.checkIcarthasIdOrToken();
				}
			});
		}
	}

	sharingDataServicCartItems() {
		this.facade.count().subscribe((cartDetails: any) => {
			if (cartDetails != 1) {
				this.countNotificationCartItems(cartDetails);
			}
		});
	}

	sharingDataServicFavItems() {
		this.facade.fave().subscribe((faveItems: any) => {
			this.getFavorites();
		});
	}

	getFromCart() {
		if (localStorage.getItem('cartId')) {
			this.cartId = localStorage.getItem('cartId');
		} else if (localStorage.getItem('cartId_Guest')) {
			this.cartId = localStorage.getItem('cartId_Guest');
		}
		if (this.cartId) {
			this.cartDetailLength = 0;
			const variables = {
				getCartInput: { cartId: this.cartId },
			};
			this.facade.getCart(variables).subscribe(
				({ data }: any) => {
					this.countNotificationCartItems(data?.cart?.cartDetails);
					this.sendCartItemsToCartNotifications([...data?.cart?.cartDetails]);
				},
				(err: any) => {
				}
			);
		}
	}

	checkIcarthasIdOrToken() {
		if (localStorage.getItem('AUTH_TOKEN')) {
			this.checkCartByToken();
		} else {
			this.getFromCart();
		}
	}

	checkIfCartIdExist() {
		if (localStorage.getItem('cartId_Guest')) {
			const variables = {
				getCartInput: { cartId: localStorage.getItem('cartId_Guest') },
			};

			this.facade.getCart(variables).subscribe(
				(res: any) => {
					if (res?.data?.cart?.cartDetails.length > 0) {
						this.newCashCartDetails = res?.data?.cart?.cartDetails;
						this.addCashCartToByerCart([...this.newCashCartDetails]);
						this.newCashCartDetails = [];
						localStorage.removeItem('cartId_Guest');
						localStorage.setItem('cartId', this.cartIdByToken);
						this.cartIdByToken = null;
						this.cartProductsByToken = [];
					} else {
						localStorage.setItem('cartId', this.cartIdByToken);
						if (this.cartProductsByToken?.length > 0) {
							this.sendCartItemsToCartNotifications([
								...this.cartProductsByToken,
							]);
						}
					}
				},
				(err: any) => {
					// this.handleErrorsService.handleError(err);
				}
			);
		} else {
			localStorage.setItem('cartId', this.cartIdByToken);
			if (this.cartProductsByToken?.length > 0) {
				this.sendCartItemsToCartNotifications([...this.cartProductsByToken]);
			}
		}
	}

	checkCartByToken() {
		this.facade.getCartByer().subscribe(
			(res: any) => {
				if (res?.data?.buyerCart?.cartId) {
					this.cartIdByToken = res?.data?.buyerCart?.cartId;
					this.cartProductsByToken = res.data.buyerCart.cartDetails;
					if (localStorage.getItem('cartId_Guest')) {
						this.checkIfCartIdExist();
					} else {
						localStorage.setItem('cartId', this.cartIdByToken);
						this.sendCartItemsToCartNotifications([
							...this.cartProductsByToken,
						]);
						this.cartProductsByToken = [];
						this.cartIdByToken = null;
					}
				}
			},
			(err: any) => {
				this.createCartId().then((res) => {
					this.checkIfCartIdExist();
				});
			}
		);
	}

	createCartId() {
		return new Promise((resolve) => {
			this.facade.creteCart().subscribe((res: any) => {
				this.cartIdByToken = res.data.createCart.cartId;
				resolve(this.cartIdByToken);
			});
		});
	}

	addCashCartToByerCart(newCashCartDetails: any) {
		newCashCartDetails.forEach((el: any) => {
			if (el) {
				let variables = {
					addToCartInput: {
						cartId: this.cartIdByToken,
						variantId: el.variant.variantId,
						qty: el.qty,
					},
				};
				this.addCashProductsTocar(variables);
			}
		});
		localStorage.removeItem('cartId_Guest');
		localStorage.setItem('cartId', this.cartIdByToken);
		this.sendCartItemsToCartNotifications([
			...this.cartProductsByToken,
			...this.newCashCartDetails,
		]);
	}

	addCashProductsTocar(variables: any) {
		this.facade.addToCart(variables).subscribe((res: any) => {
			if (res) {
			}
		});
	}

	countNotificationCartItems(cartDetails: any) {
		this.cartDetailLength = 0;
		if (cartDetails) {
			cartDetails.forEach((el: any) => {
				if (el.qty) {
					this.cartDetailLength = this.cartDetailLength + el.qty;
				}
			});
			localStorage.setItem('CART_LENGTH', this.cartDetailLength);
		}
	}

	getFavorites() {
		if (localStorage.getItem('AUTH_TOKEN')) {
			this.facade.getWishList().subscribe(
				(res: any) => {
					if (res?.data?.favourites) {
						this.wishList = [...res?.data?.favourites];
					}
				},
				(error: any) => {
					this.facade.handleError(error);
				}
			);
		} else {
			this.wishList = [];
		}
	}

	navigateSearch() {
		this.facade.navigate(['/cart']);
	}

	sendCartItemsToCartNotifications(res: any) {
		this.facade.nextCount(res);
	}

	sendFaveItemToFaveNotification(data: any) {
		this.facade.nextFave(data);
	}

	searchByKey() {
		const variables = {
			searchProduct: {
				keyword: this.searchKey,
			},
		};

		this.facade.getProductByKey(variables).subscribe((res: any) => {
			if (res?.data?.searchByKeyword) {
				this.products = res?.data?.searchByKeyword;
			} else {
				// this.products = [];
			}
		});
	}

	changeLang(lang: any) {
		this.facade.removeItem('lang');
		localStorage.setItem('lang', lang);
		// this.global?.lang = lang;
		this.refreshPage();
	}

	getLang() {
		if (localStorage.getItem('lang')) {
			this.currentLang = localStorage.getItem('lang');
		} else {
			this.currentLang = 'en';
		}
	}

	refreshPage() {
		window.location.reload();
		// this._document.defaultView.location.reload();
	}

	closeDropdown(data: any) {
		this.products = [];
	}

	navigateDropDownSearch(event: any) {

		this.searchKey = this.products[event].name;
	}

	searchFocus() {
		if (this.searchKey) {
			this.searchByKey();
		}
	}

	searchWithEnter(data?: any) {
		if (data?.name) {
			this.searchKey = data?.name;
		} else {
			this.searchKey;
		}
		this.navigateToSrarchPageWithProducts();
	}

	navigateToSrarchPageWithProducts() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				product: this.searchKey,
			},
		};
		this.facade.navigate(['./search'], navigationExtras);
		this.products = [];
	}

	initLanguage() {
		if (localStorage.getItem('lang')?.indexOf("en") != -1) {
			this.language = 'العربية';
		} else {
			this.language = 'English';
		}
	}

	switchLang() {
		if (localStorage.getItem('lang')?.indexOf("en") != -1) {
			window.location.reload();
			localStorage.setItem('lang', "ar");
		} else {
			window.location.reload();
			localStorage.setItem('lang', "en");
		}
	}
}
