import { Injectable } from '@angular/core';
import { BaseServiceService } from '@core/graph-ql/base-service/base-service.service';
import { GraphFunctionsTypesInputs } from '@core/graph-ql/graph-functions/graph-functions-types-inputs';
import { QueryFull } from '@core/models/graph-models';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends GraphFunctionsTypesInputs {
  constructor(private baseServiceService: BaseServiceService) {
    super();
  }

  getSlider() {
    const input = {} as QueryFull;
    input.func = this.FUNC_SLIDER;
    input.return = this.SLIDER;
    return this.baseServiceService.generalQuery(input);
  }

  popularCategory(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_POPULAR_CATEGORIES;
    input.variables = variables;
    input.return = this.POPULAR_CATEGORY;
    input.paginatioin = this.INPUT_PAGINATION;
    input.paginatioinType = this.TYPE_PAGINATION;
    return this.baseServiceService.generalQueFull(input);
  }

  getPopularSubCategory(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_POPULAR_SUB_CATEGORIES;
    input.paginatioin = this.INPUT_PAGINATION;
    input.paginatioinType = this.TYPE_PAGINATION;
    input.variables = variables;
    input.return = this.POPULAR_SUB_CATEGORY;
    return this.baseServiceService.generalQueFull(input);
  }

  getNewArrivals(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_NEW_ARRIVALS;
    input.paginatioin = this.INPUT_PAGINATION;
    input.paginatioinType = this.TYPE_PAGINATION;
    input.variables = variables;
    input.return = this.NEW_ARRIVALS;
    return this.baseServiceService.generalQueFull(input);
  }

  testimonials(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_TESTIMONIALS;
    input.paginatioin = this.INPUT_PAGINATION;
    input.paginatioinType = this.TYPE_PAGINATION;
    input.variables = variables;
    input.return = this.TESTIMONIALS;
    return this.baseServiceService.generalQueFull(input);
  }




  getProductsWeLove(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_PRODUCTS_WE_LOVE;
    input.paginatioin = this.INPUT_PAGINATION;
    input.paginatioinType = this.TYPE_PAGINATION;
    input.variables = variables;
    input.return = this.PRODUCT_WE_LOVE;
    return this.baseServiceService.generalQueFull(input);
  }


  getSittingHome() {
    const input = {} as QueryFull;
    input.func = this.FUNC_SETTING;
    input.return = this.HOME_SETTING;
    return this.baseServiceService.generalQuery(input);
  }

  getSolgans() {
    const input = {} as QueryFull;
    input.func = this.FUNC_SLOGAN;
    input.return = this.SLOGANS;
    return this.baseServiceService.generalQuery(input);
  }

  wearMood(variables: any) {
    const input = {} as QueryFull;
    input.func = this.FUNC_WEARMOODS;
    input.return = this.WEARMOODS;
    // input.paginatioin = this.INPUT_PAGINATION;
    // input.paginatioinType = this.TYPE_PAGINATION;
    return this.baseServiceService.generalQuery(input);
  }
}

