import { Component, OnInit } from '@angular/core';
import { SharingDataService } from '@core/services/sharing-data/sharing-data.service';

@Component({
  selector: 'romeo-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  showAccMenu!:boolean;
  showSettinsTab!:boolean;
  token!: any;
  constructor(public sharingService: SharingDataService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('AUTH_TOKEN');
    this.sharingService.authenticated.subscribe((data) =>{
      if(data)  this.token = localStorage.getItem('AUTH_TOKEN'); else this.token = null;
    })
   

  }

}
