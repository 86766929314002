<div class="productCard">
  <div class="productCard-header">
    <a
      [routerLink]="['/product']"
      class="productCard-header-thumb"
      title="{{ name }}"
      (click)="navigate()"
    >
      <app-image [src]="image" alt="{{ name }}" ratio="is-1-1"></app-image>
    </a>

    <ul class="productCard-header-actions">
      <li *ngIf="wishList != 'category'">
        <span
          [ngClass]="{ 'is-active': fav }"
          *ngIf="wishList != 'wishList'"
          (click)="addToFavorite()"
        >
          <ng-container>
            <svg-icon src="assets/icons/heart-fill.svg"></svg-icon>
          </ng-container>
        </span>

        <span
          [ngClass]="{ 'is-active': fav }"
          (click)="deleteItemFromFave()"
          *ngIf="wishList == 'wishList'"
        >
          <ng-container>
            <svg-icon src="assets/icons/delete.svg"></svg-icon>
          </ng-container>
        </span>
      </li>
      <li *ngIf="wishList != 'category'">
        <span [ngClass]="{ 'is-active': cart }" (click)="addProductToCart()">
          <ng-container>
            <svg-icon src="assets/icons/cart-check.svg"></svg-icon>
          </ng-container>
        </span>
      </li>
    </ul>
    <div class="productCard-header-sale" *ngIf="salePrice">
      {{ "product.sale" | translate }}
    </div>
  </div>

  <div class="productCard-body">
    <h4 class="productCard-title">
      <a [routerLink]="['/product']">
        {{ name }}
      </a>
    </h4>
    <div class="d-flex justify-content-between" *ngIf="wishList != 'category'">
      <p class="productCard-price mb-0">
        <span class="me-1"
          >{{ salePrice ? salePrice : (price | number: ".0-2") }}
          <!-- {{ currency?.isoAlpha_3 }} -->
          {{ "currency.EGP" | translate }}
        </span>
        <span class="productCard-price-old" *ngIf="salePrice"
          >{{ salePrice ? price : (salePrice | number: ".0-2") }}
          <!-- {{ currency?.isoAlpha_3 }} -->
          {{ "currency.EGP" | translate }}
        </span>
      </p>

      <span class="productCard-rate" *ngIf="wishList != 'category'">
        <svg-icon src="assets/icons/star.svg" class="me-xxs"></svg-icon>
        <span class="fs-xs"
          >{{ overallRating }}
          <span class="productCard-rate-num">({{ qty }})</span></span
        >
      </span>
    </div>
  </div>
</div>
