<form class="needs-validation" [formGroup]="updateAddressForm" (ngSubmit)="updateAdress()" novalidate>

  <div class="modal  fade show d-block" tabindex="-1" *ngIf="show" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content ">
        <div class="modal-body">
          <h3 class="fs-lg mb-md">{{'shared.addressDetails' | translate }}</h3>

          <div class="row">
            <div class="col-md-6 mb-xs">
              <div class="mb-xs">
                <label class="form-label">{{'shared.city' | translate }}</label>
                <select class="form-select" formControlName="cityId" (change)="changeCity($event)"
                [class.is-invalid]="cityId?.invalid && (cityId?.dirty || cityId?.touched)">
                  <option *ngFor="let city of cities" [value]="city.cityId">{{city?.name || city?.nameAr }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-xs">
              <label class="form-label">{{'shared.area' | translate }}</label>
              <select class="form-select" formControlName="areaId"
              [class.is-invalid]="areaId?.invalid && (areaId?.dirty ||areaId?.touched)">
                <option *ngFor="let area of sellectedAreas" [value]="area?.areaId">{{area?.name || area?.nameAr}}</option>
              </select>
            </div>
          </div>



          <div class="mb-xs">
            <label class="form-label">{{'shared.address' | translate }}</label>
            <input type="text" class="form-control" formControlName="address" required>
            <span class="textInput-message"
            *ngIf="address?.hasError('required') && (address?.dirty || address?.touched)">{{'shared.addressRequired' | translate }}</span>
          </div>

          <div class="mb-xs">
            <label class="form-label">{{'shared.type' | translate }}</label>
            <select class="form-select" formControlName="type" required
            [class.is-invalid]="type?.invalid && (type?.dirty ||type?.touched)" placeholder="Type">
              <option *ngFor="let type of addressTypes" [value]="type?.name">{{  lang ? type?.name : type?.nameAr }}</option>
            </select>
            <span class="textInput-message" *ngIf="type?.hasError('required') && (type?.dirty || type?.touched)">
              {{'shared.typeRequired' | translate }}
            </span>
          </div>


          <div class="mb-xs">
            <label class="form-label">{{'shared.deliveryNote' | translate }}</label>
            <textarea class="form-control"  formControlName="deliveryNote"></textarea>
          </div>


          <button type="button" class="btn btn-primary me-xs pe-md ps-md"
          (click)="updateAdress()"
              [disabled]="!updateAddressForm.valid || isAddAddress">
          <span *ngIf="!isSubmitting">{{ 'shared.save' | translate }}</span>
          <img  *ngIf="isSubmitting" src="../../../../assets/images/spinner.gif" alt="" class="spinner">
        </button>
          <button type="button" class="btn btn-link" (click)="close(false)">{{ 'shared.cancel' | translate }}</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal-backdrop fade show" *ngIf="show" (click)="close(false)"></div>
</form>
