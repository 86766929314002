import { LocalStorageService } from './../../services/local-storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SidebarService } from '@core/services/sidebar/sidebar.service';
import { Location } from'@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
  ]
})
export class SidebarComponent implements OnInit {
  language!:string;
  @Output() hideSideBar:EventEmitter<any> = new EventEmitter();
  constructor(public SidebarService: SidebarService, public translateService: TranslateService, public storage: LocalStorageService,public location: Location) {}
  ngOnInit(): void {
    this.initLanguage();
  }

  initLanguage(){
    if(this.storage.get('lang').indexOf("en") != -1) {
      this.language = 'العربية';
    }else {
      this.language = 'English';
    }
  }
  switchLang(){
    if(this.storage.get('lang').indexOf("en") != -1) {
      window.location.reload();
      this.storage.set('lang',"ar");

      // this.storage.set('lang','ar');
      // this.language = 'English';
    }else {
      window.location.reload();
      this.storage.set('lang',"en");

      // this.translateService.use('en');
      // this.language = 'العربية';
    }
  }

  hideSide(){
    this.hideSideBar.emit(true);
  }
}
