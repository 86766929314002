import { FacadeService } from './../../../core/facade/facade.service';
import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@core/utils/utils';
import { StringValueNode } from 'graphql';

@Component({
  selector: 'bundle-card',
  templateUrl: './bundle-card.component.html',
  styleUrls: ['./bundle-card.component.scss'],
})
export class BundleCardComponent implements OnInit {
  @Input() featuredImage: any;
  @Input() name: any;
  @Input() variants: any;
  @Input() discount: any;
  util = new Utils();
  currency: any;
  totalPrice: any;

  constructor(private facade: FacadeService) {
    this.currency = JSON.parse(sessionStorage.getItem('currency')!);
    this.currency = this.currency[0];
  }

  ngOnInit(): void {
    this.calcTotalPrice();
  }

  addToWhishlist() {
    this.variants.forEach((variant: any) => {
      this.addToFavorite(variant.variantId);
    });
  }

  addToFavorite(variantId: string) {
    if (localStorage.getItem('AUTH_TOKEN')) {
      const variables = {
        addToFavouriteInput: {
          variantId: variantId,
        },
      };
      this.facade.addFavorite(variables).subscribe(
        (res: any) => {
          if (res) {
            this.facade.success('Success');
            this.sendFaveItemToFaveNotification(res);
            //   this.addedToFav = true;
            // localStorage.setItem('FAV_NOTIFICATION', localStorage.getItem('FAV_NOTIFICATION') );
          }
        },
        (error: any) => {
          this.facade.handleError(error);
        }
      );
    } else {
      localStorage.setItem('redirectTo', this.facade.url());
      this.facade.navigate(['/auth'], {
        queryParams: { subCategoryId: variantId },
      });
    }
  }
  sendFaveItemToFaveNotification(data: any) {
    this.facade.nextFave(data);
  }

  addToCart() {
    this.variants.forEach((variant: any) => {
      this.addProductToCart(variant.variantId);
    });
  }

  addProduct(variantId: string) {
    // this.cartAdd = true;
    const variables = {
      addToCartInput: {
        variantId: variantId,
        cartId: localStorage.getItem('cartId')
          ? localStorage.getItem('cartId')
          : localStorage.getItem('cartId_Guest'),
      },
    };
    this.facade.addToCart(variables).subscribe(
      (res: any) => {
        // this.cartAdd = false;
        if (res) {
          this.facade.cartOverlayToggle();
          localStorage.setItem('CART_LENGTH', res.data.addToCart.cartDetails);
          this.sendCartItemsToCartNotifications(res.data.addToCart.cartDetails);
          this.facade.success(`Added To Cart Succesfully`);
          // this.router.navigate(['/checkout'])
        }
      },
      (error: any) => {
        // this.cartAdd = false;
        this.ifNoSuchCart(error, variantId);
        // this.facade.handleError(error);
      }
    );
  }

  sendCartItemsToCartNotifications(res: any) {
    this.facade.nextCount(res);
  }

  ifNoSuchCart(error: any, variantId: string) {
    if (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 404) {
      if (
        error?.graphQLErrors[0]?.extensions?.response?.message?.includes(
          'no enough quantity'
        )
      ) {
        this.facade.info(
          error?.graphQLErrors[0]?.extensions?.response?.message
        );
      } else {
        localStorage.removeItem('cartId');
        this.facade.removeItem('cartId_Guest');
        this.facade.createCart().then((res: any) => {
          if (res) {
            this.addProduct(variantId);
          }
        });
      }
    }
  }

  addProductToCart(variantId: string) {
    if (
      localStorage.getItem('cartId') ||
      localStorage.getItem('cartId_Guest')
    ) {
      this.addProduct(variantId);
    } else {
      this.facade.createCart().then((res: any) => {
        this.addProduct(variantId);
      });
    }
  }

  gotoProductDetail(variantId: string, name: string) {
    this.facade.navigate(['/product', this.util.createSlug(name, variantId)]);
  }

  calcTotalPrice() {
    this.totalPrice = 0;
    this.variants.forEach((variant: any) => {
      this.totalPrice += variant?.price;
    });
  }
}
