import { Injectable } from '@angular/core';
import { FacadeService } from '@core/facade/facade.service';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorsService {

  constructor(
    private facade: FacadeService
    ) { }

  handleError(error: any) {
    if (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 401) {
      this.facade.clear();
      this.facade.navigate(['/auth']);
      this.facade.info(error?.graphQLErrors[0]?.extensions?.response?.message);
    } else if  (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 400) {
      this.facade.info(error?.graphQLErrors[0]?.extensions?.response?.message);
    } else if  (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 409) {
      this.facade.info(error?.graphQLErrors[0]?.extensions?.response?.message);
    } else if  (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 404 && error?.graphQLErrors[0]?.path.indexOf('addToCart') <= -1) {
      this.facade.info(error?.graphQLErrors[0]?.extensions?.response?.message);
    } else if  (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 403) {
      this.facade.clear();
      this.facade.navigate(['/auth']);
      this.facade.info(error?.graphQLErrors[0]?.extensions?.response?.message);
    }else if  (error?.graphQLErrors[0]?.extensions?.response?.statusCode == 404 && error?.graphQLErrors[0]?.path.indexOf('addToCart') > -1) {
      this.facade.removeItem('cartId');
      this.facade.removeItem('cartId_Guest');
      }
  }

}
