<div class="p-md bundle position-relative">
  <div class="d-flex align-items-center">
    <div class="w-50 bundle__header bundle__preview position-relative">
      <!-- [routerLink]="['/product']" -->

      <a class="bundle-header-thumb bundle__preview" title="{{ name }}">
        <app-image [src]="featuredImage" ratio="is-1-1"></app-image>
        <div
          class="bundle__preview--hover position-absolute"
          (click)="addToWhishlist()"
        >
          <img src="../../../../assets/images/bundle-whishlist.svg" alt="" />
        </div>
      </a>
    </div>
    <div class="w-50 align-self-start ms-4 d-flex flex-column">
      <div class="bundle__title">
        {{ "bundle.get" | translate }} {{ discount }}%
        {{ "bundle.offWhenYouBuy" | translate }} {{ variants?.length }}
        {{ "bundle.items" | translate }}
      </div>
      <div class="bundle__description">{{ name }}</div>
      <div class="d-flex align-items-start w-100 bundle__variants mb-3">
        <div
          class="bundle__images w-100 me-2 position-relative"
          *ngFor="let variant of variants; let i = index"
          (click)="
            gotoProductDetail(
              variant?.variantId,
              variant.nameAr || variant?.name
            )
          "
        >
          <app-image [src]="variant?.featuredImage"></app-image>
          <div class="bundle__images--hover position-absolute">
            {{ "bundle.show" | translate }}
          </div>
        </div>
      </div>
      <div class="mt-auto bundle__price">
        {{ (totalPrice * (100 - discount)) / 100  | number: ".0-2"}}
        <!-- {{ currency?.isoAlpha_3 }} -->

        {{ "currency.EGP" | translate }}

        <div class="d-inline-block">
          <small class="bundle__price--sale"
            ><del
              >{{ totalPrice | number: ".0-2" }}

              <!-- {{ currency?.isoAlpha_3 }} -->
              {{ "currency.EGP" | translate }}
            </del></small
          >
        </div>
      </div>
    </div>
  </div>
  <div class="bundle__cart position-absolute" (click)="addToCart()">
    <img src="../../../../assets/images/bundle-cart.svg" alt="" />
  </div>
</div>
