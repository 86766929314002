<div class="rate">
  <div class="rate-colored" [style.width.%]="(rate*100)/5">
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
  </div>
  <div class="rate-uncolored">
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
    <svg-icon src="assets/icons/star.svg"></svg-icon>
  </div>
</div>
